import { useState } from "react";
import { Collapse } from "./Collapse";
import { Container, Subtitle, Title } from "./styles";

export function Faq() {
    const [switchCollapse, setSwitchCollapse] = useState(0);
    return (
        <Container>
            <Title>Encontre a resposta para às dúvidas mais comuns </Title>
            <Subtitle>Você pode abrir ou fechar as perguntas para ler as instruções que precisa.</Subtitle>
            <div>
                <Collapse isCollapsed={switchCollapse === 1} active={() => { switchCollapse === 1 ? setSwitchCollapse(0) : setSwitchCollapse(1) }}
                    question="Porque preciso atualizar meu Acesso VR?">
                    <p>Manter suas informações atualizadas é essencial para conseguir entrar em nossas plataformas e usar os serviços que precisa.</p>
                    <p>Lembre-se de informar e-mail que só você usa, pois frequentemente enviamos códigos para verificar se é você quem está tentando entrar nas plataformas e, assim, garantimos que suas informações estão seguras.</p>
                </Collapse>
                <Collapse isCollapsed={switchCollapse === 2} active={() => { switchCollapse === 2 ? setSwitchCollapse(0) : setSwitchCollapse(2) }}
                    question="O que fazer se não tenho acesso ou não reconheço o e-mail nem o telefone cadastrados?">
                    <p>Se você não tem acesso ou não reconhece o e-mail ou o telefone cadastrados, entre em contato com o suporte para obter ajuda.</p>
                </Collapse>
                <Collapse isCollapsed={switchCollapse === 3} active={() => { switchCollapse === 3 ? setSwitchCollapse(0) : setSwitchCollapse(3) }}
                    question="Por que preciso informar um código para atualizar meu Acesso VR?">
                    <p>Enviamos um código para seu e-mail ou celular cadastrado para ter certeza que é você quem está tentando atualizar seu Acesso VR. Dessa forma, suas informações ficam mais protegidas contra golpes ou fraudes.</p>
                </Collapse>
                <Collapse isCollapsed={switchCollapse === 4} active={() => { switchCollapse === 4 ? setSwitchCollapse(0) : setSwitchCollapse(4) }}
                    question="Não recebi o código de verificação. O que fazer?">
                    <p>1º Confira na sua caixa de Spam (lixo eletrônico) ou promoções.</p>
                    <p>2º Tente enviar outro código.</p>
                    <p>3º Se mesmo assim não recebeu o código, entre em contato com a gente pelo telefone: 4004-4938.</p>
                </Collapse>
                <Collapse isCollapsed={switchCollapse === 5} active={() => { switchCollapse === 5 ? setSwitchCollapse(0) : setSwitchCollapse(5) }}
                    question="Existe outro Acesso VR com o meu CPF, o que fazer?">
                    <p>• Se você já atualizou seu Acesso VR antes, basta entrar usando o CPF e a nova senha informadas durante a atualização.</p>
                    <p>• Se você não lembra a senha ou não atualizou seu Acesso VR, vá em “Esqueci minha senha” na tela inicial da plataforma. Vamos mandar as instruções de redefinição de senha para o e-mail cadastrado.</p>
                    <p>• Se ao ir em “Esqueci minha senha” e informar seu CPF, o e-mail que aparecer não for o seu ou não tiver mais acesso a ele, entre em contato com a gente pelo número 4004-4938.</p>
                </Collapse>
                <Collapse isCollapsed={switchCollapse === 6} active={() => { switchCollapse === 6 ? setSwitchCollapse(0) : setSwitchCollapse(6) }}
                    question="Por que não devo usar um e-mail compartilhado?">
                    <p>O Acesso VR é individual. Para garantir a proteção dos seus dados pessoais, não cadastre e-mails coletivos, como os usados por equipes. Informe um e-mail que só você usa e que sempre acessa.</p>
                </Collapse>
            </div>
        </Container>
    )
}