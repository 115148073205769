import { styled } from "@stitches/react";

export const Container = styled('div', {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    zIndex: '9999',
    alignItems: 'center',
    justifyContent: 'center',
})

export const Content = styled('div', {
    maxWidth: '90%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
})