import { styled } from "@stitches/react";

export const Container = styled('div', {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    gap: '10px',
    borderLeft: '3px solid #02D72F',
    padding: '5px 10px',
    margin: '10px 0',
    width: '100%',
    maxWidth: '600px',
    '& div': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
    },
    '& h4': {
        fontWeight: 'semibold',
        fontSize: '12px',
    },
    '& p': {
        fontSize: '12px',
    }
})