import { styled } from "@stitches/react";

export const Text = styled('p', {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
})

export const Container = styled('div', {
    minWidth: '300px',
})

export const Resend = styled('button', {
    fontSize: '14px',
    fontWeight: 600,
    color: '#034D2E',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    textDecoration: 'underline',
})