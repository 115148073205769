import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container, NewLoading } from '../ReRegistration/styles';
import { TokenForm } from '../../components/FormsScreen/TokenForm';
import { ReadonlyForm } from '../../components/FormsScreen/ReadonlyForm';
import { FormEdit } from '../../components/FormsScreen/FormEdit';
import { Conclusion } from '../../components/FormsScreen/Conclusion';
import { SelectOptIn } from '../../components/FormsScreen/SelectOptIn';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { handleGetUserInfo } from '../../services/userService';
import { PasswordForm } from '../../components/FormsScreen/PasswordForm';
import { getUserInfo } from '../../services/getUserInfo';
import apiService from '../../services/api';
import { handleGetIp } from '../../services/getIp';
import { verifyClientAndRedirectUri } from '../../services/verifyClientId';


export default function PageEdit() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepSwitch, setStepSwitch] = useState(99);
  const [start, setStart] = useState(true);
  const token = useAuthToken();
  const redirectUrl = urlParams.get('redirect_uri') ?? '';

  async function postQualification(cpf: string) {
    setIsLoading(true);
    apiService.post('/acesso-vr/v1/qualificacoes', {
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: urlParams.get('redirect_uri')
      },
      conta: {
        cpf: cpf
      },
      processo: 'edicao'
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    }).then((res) => {
      if (token) {
        handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, res.data.idQualificacao);
      }
    }).catch((err) => {
      const inputString = err.response.data.mensagens[0].descricao;
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);

      if (match) {
        const id = match[1];
        if (token) {
          handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, id);
        }
      } else {
        setIsLoading(false);
        setStepSwitch(0);
      }
    })
  }

  useEffect(() => {
    localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');

    if (token && start) {
      localStorage.clear();
      localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
      localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
      localStorage.setItem('token', token);
      setStart(false);
      getUserInfo().then(userInfo => {
        if (userInfo === null) {
          setIsLoading(false);
          setStepSwitch(401);
          return;
        } else if (!userInfo?.name) {
          setIsLoading(false);
          setStepSwitch(401);
          return;
        }
        postQualification(userInfo?.cpf ?? '');
        setStart(false);
      })
      handleGetIp()
    }
    if (token === null) {
      setIsLoading(false);
      setStepSwitch(401);
    }
  }, [token]);

  return (
    <Container>
      {isLoading &&
        <NewLoading>
          <CircularLoading isLoading={isLoading} />
        </NewLoading>
      }
      {stepSwitch === 0 && <NotFound  codeError={'-9'} title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepSwitch === 401 && <NotFound  codeError={'-6'} title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepSwitch === 1 && <ReadonlyForm changeLoading={setIsLoading} changeStep={setStepSwitch} prev edit />}
      {stepSwitch === 2 && <SelectOptIn edit changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 3 && <TokenForm edit changeStep={setStepSwitch} changeLoading={setIsLoading} thisStep={stepSwitch} />}
      {stepSwitch === 4 && <FormEdit changeLoading={setIsLoading} edit changeStep={setStepSwitch} />}
      {stepSwitch === 5 && <ReadonlyForm changeLoading={setIsLoading} edit changeStep={setStepSwitch} />}
      {stepSwitch === 6 && <PasswordForm edit changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 7 && <Conclusion title="Atualizamos o e-mail e a senha do seu Acesso VR" />}
      {stepSwitch === 9 && <ReadonlyForm changeLoading={setIsLoading} edit changeStep={setStepSwitch} />}
      {stepSwitch === 91 && <ReadonlyForm changeLoading={setIsLoading} valid edit changeStep={setStepSwitch} />}
      {stepSwitch === 11 && <NotFound  codeError={'-99'} title="Já existe uma edição em andamento" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUrl} />}
      {stepSwitch === 22 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} edit conflictEmail />}
      {stepSwitch === 33 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} edit conflictPhone />}
      {stepSwitch === 44 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} edit conflictCpfprop />}
    </Container>
  );
}
