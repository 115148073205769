import { useState, useCallback } from 'react';
import moment from 'moment';
import forge from 'node-forge';

const publicKeyBase64 = `LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUFyR1lJNmkvT1F5bVhzeTgzSXIvdQprZUlHVm5qNEhiemVPUm5GNGJ5Ly9YZEhSYUhwSzlraUlIeldMRHhUSkFJaktnRFhDRE90OENRV0dTa2FTNzdhCmI4M256YmlNbnAvUU9Db09XODRFMWp4d21ydVBKWFZGU0tRTWZBVjlqNWcxRklndnpWeVpPWXA5QXl6NStOcjQKVHRMdUQvaDM2SkJ1OVc2bHdMaXBVSGdyQ3pmSGFTek9XS2I4STlUV1h0NEtQcU9HRkE1SGNUODlUaXBxdis1VQo2R3VKazl4c3diV3VrUGdTd3ZWSWdSUVM5RGV6bERpTmpaaEJSYkdHVHFSZnk4cnRDYmIxUDZ5a0RSUk9lQ202Ck5EZmpPcjA4VmVtOG15VU1qYkFZSEtFUElxQWQrdEU2ZUt5ZnVsQ0RQcjdDOWdLMjNVNmFmNlZKRzYxV0tQVVAKSmNpV3phOTUybFFMbDdlN01LUzBQU0ZEOWIxWUhNU1NBenhGSGZTYXdJemNKRldIcWJtd3g0a2o4Q0l5VVlVeQpTbnNzamdDOXMzVjZDa3M1ZTNPV1hVTExkSGRGVVJkVmhNb3BzenNEdzJ3OEIvTUp0SFdWZ0JCcEcycVZyazJCCi81akRkaFhxdDZoekptaGplc2lZMTdUb21VZ1Q1VkVvRWdVQiszTzBNWXhjd29wZWNQM1ltUTdCcTkxYWJ4WXEKSXZCNiswY1k0N2d0RHBIODY4RVBYQjhFb1d6bWJVUWoyZHdQTDlxbHI3T2xnWm9NMmpnc1NyanVHQUo3ckt2eQp5b1FyVmtlaHFHaTdHRGVNU1FtaXcvdm5GSVRpbHdSOWV1RHBMNnhtTTlwNW0raEhsOU1lWVpvUklRMHpuMzR3CmZBempQYUxPN3lPOFJrN0VLTWdFemFVQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==`;

interface PKCEData {
  xData: string;
  xSignature: string;
}

export const useEncription = (): [PKCEData | null, boolean, Error | null, () => void] => {
  const [pkceData, setPkceData] = useState<PKCEData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const generateAESKeyBase64 = () => {
    const aesKey = forge.random.getBytesSync(32);
    return forge.util.encode64(aesKey);
  };

  const encryptWithAES = (aesKey: string, data: string) => {
    const iv = forge.random.getBytesSync(16);
    const decodedKey = forge.util.decode64(aesKey);

    const cipher = forge.cipher.createCipher('AES-CBC', decodedKey);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(data));
    cipher.finish();

    return {
      ivHex: forge.util.bytesToHex(iv),
      encryptedDataBase64: forge.util.encode64(cipher.output.getBytes()),
    };
  };

  const encryptAESKey = (aesKey: string) => {
    const decodedPublicKey = forge.util.decode64(publicKeyBase64);
    const publicKey = forge.pki.publicKeyFromPem(decodedPublicKey);
    const encryptedKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: { md: forge.md.sha256.create() },
    });

    return forge.util.encode64(encryptedKey);
  };

  const generatePKCEData = useCallback(() => {
    setLoading(true);
    setError(null);

    try {
      const message = moment.utc().toISOString();
      const aesKeyBase64 = generateAESKeyBase64();
      const { ivHex, encryptedDataBase64 } = encryptWithAES(aesKeyBase64, message);
      const encryptedAESKeyBase64 = encryptAESKey(aesKeyBase64);

      setPkceData({
        xData: encryptedDataBase64,
        xSignature: ivHex + encryptedAESKeyBase64,
      });
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  return [pkceData, loading, error, generatePKCEData];
};
