import { TextInput } from "@vr/ds-react";
import { SubtitleFormEdit, TitleFormEdit } from "./styles";

interface InsertNameProps {    
    readonly newAccount?: boolean;
    invalidName: boolean;
    name: string;
    lastName: string;
    setName: (value: string) => void;
    setLastName: (value: string) => void;
}

export function InsertName( { newAccount, invalidName, name, lastName, setName, setLastName }: InsertNameProps ) {
    const isEmpty = name === '' && lastName === '' || name === 'null' || lastName == 'null';
    return (
        <>
            <TitleFormEdit>{newAccount ? "Quem irá usar este Acesso VR?" : "Quem usa este Acesso VR?"}</TitleFormEdit>
            <SubtitleFormEdit>
                {isEmpty ? "Insira seu nome e sobrenome" : "Verifique se seu nome e sobrenome esta corretos"}
            </SubtitleFormEdit>
            <TextInput
                label="Nome"
                hasError={invalidName}
                hintText={
                    invalidName ? 'O nome deve conter pelo menos duas palavras' : ''
                }
                value={name === 'null' ? "" : name}
                onChange={e => {
                    setName(e.target.value);
                }}
            />
            <TextInput
                label="Sobrenome"
                value={lastName === 'null' ? "" : lastName}
                onChange={e => setLastName(e.target.value)}
            />
        </>
    );
}