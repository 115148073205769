import { styled } from "@stitches/react";

export const Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    width: '100%',
    gap: '10px',
  });

  export const ValidatorsBox = styled('div', {

  })

  export const ValidateItem = styled('div', {
    fontSize: '12px',
    fontWeight: 600,
    color: '#1B2126',
    '&.error': {
        color: '#BF0000',
    },
    '&.success': {
        color: '#034D2E',
    }
  })