import axios, { AxiosError, AxiosResponse } from 'axios';

import Environment from '../../enviroment';

const apiService = axios.create({
  baseURL: Environment.apiURL + Environment.apiPath,
  headers: { 'Content-Type': 'application/json' },
});

apiService.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      console.log('401');
    }

    return Promise.reject(error);
  },
);

export default apiService;
