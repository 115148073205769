import { createStitches } from "@stitches/react";


const { styled, css } = createStitches({
  media: {
    mobile: '(max-width: 740px)',
    tablet: '(max-width: 900px)',
  },
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '800px',
  width: '100%',
  alignItems: 'center',
  '& div': {
    maxWidth: '380px',
    width: '100%'
  }
})

export const UploadLine = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const InputLine = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '10px',
  '@tablet': {
    flexDirection: 'column',
  },
})

export const InputDate = styled('input', {
})

export const TitleFormEdit = styled('p', {
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '10px',
})

export const SubtitleFormEdit = styled('p', {
  fontSize: '16px',
  fontWeight: '500',
  textAlign: 'center',
  marginBottom: '20px',
})