import { createStitches } from "@stitches/react";


const { styled, css } = createStitches({
  media: {
    mobile: '(max-width: 740px)',
    tablet: '(max-width: 900px)',
  },
});

export const Line = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    fontSize: '13px',
    '& button': {
      padding: '0px',
      color: '#02D72F',
      fontSize: '13px',
      fontWeight: '600',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
  });

  export const ModalContent = styled('article', {
    maxHeight: '80vh',
    overflowY: 'scroll',
    margin: '0 auto',
    maxWidth: '800px',
    paddingRight: '10px',
  });