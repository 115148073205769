import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container, NewLoading } from './styles';
import { TokenForm } from '../../components/FormsScreen/TokenForm';
import { ReadonlyForm } from '../../components/FormsScreen/ReadonlyForm';
import { FormEdit } from '../../components/FormsScreen/FormEdit';
import { Conclusion } from '../../components/FormsScreen/Conclusion';
import { SelectOptIn } from '../../components/FormsScreen/SelectOptIn';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { handleGetUserInfo } from '../../services/userService';
import apiService from '../../services/api';
import { PasswordForm } from '../../components/FormsScreen/PasswordForm';
import { getUserInfo } from '../../services/getUserInfo';
import { handleVerifyState } from '../../services/verifyState';
import { handleGetIp } from '../../services/getIp';
import { verifyClientAndRedirectUri } from '../../services/verifyClientId';

export default function ReRegistration() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepSwitch, setStepSwitch] = useState(99);
  const [start, setStart] = useState(true);
  const token = useAuthToken();
  const redirecturl = urlParams.get('redirect_uri') ?? '';
  const uuid = urlParams.get('UUID') ?? '';

  async function postQualification(id: string) {
    setIsLoading(true);
    try {
      const res = await apiService.post('/acesso-vr/v1/qualificacoes', {
        preConta: {
          idPreConta: id
        },
        aplicacaoOrigem: {
          idAplicacao: urlParams.get('client_id'),
          urlRedirecionamento: redirecturl
        },
        processo: 'recadastramento'
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        },
      });

      if (token) {
        handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, res.data.idQualificacao);
      }
    } catch (err: any) {
      const inputString = err.response?.data?.mensagens[0]?.descricao;

      const regexIdConta = /ID Conta: ([a-f0-9-]{36})/;
      const regexId = /ID: ([a-f0-9-]{36})/;

      if (err.response?.status === 409) {
        const match = inputString?.match(regexIdConta);
        if (match) {
          const idConta = match[1];
          const redirecturl = urlParams.get('redirect_uri') ?? '';

          let redirectWithIdConta = redirecturl.includes('?')
            ? `${redirecturl}&status=-3&idConta=${idConta}`
            : `${redirecturl}?status=-3&idConta=${idConta}`;

          redirectWithIdConta += `&UUID=${uuid}`
          window.location.href = redirectWithIdConta;
          return;
        }
      }

      const matchId = inputString?.match(regexId);
      if (matchId) {
        const id = matchId[1];
        if (token) {
          handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, id);
        }
      } else if (inputString === "Já existe conta no RHSSO qualificada para o usuário") {
        handleVerifyState(setStepSwitch, setIsLoading, { estado: 'conta-qualificada-existente' });
      } else if (inputString === 'O e-mail enviado já existe. Necessário troca de titularidade, foi enviado um código MFA para o e-mail solicitado.') {
        if (token) {
          handleGetUserInfo(urlParams, token, setStepSwitch, setIsLoading, id);
        }
      } else {
        setIsLoading(false);
        setStepSwitch(0);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('redirecturl', redirecturl);
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
    localStorage.setItem('userid', urlParams.get('UUID') ?? '');

    const fetchData = async () => {
      if (token && start) {
        localStorage.clear();
        localStorage.setItem('redirecturl', redirecturl);
        localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
        localStorage.setItem('userid', urlParams.get('UUID') ?? '');
        localStorage.setItem('token', token);
        setStart(false);
        try {
          const userInfo = await getUserInfo();
          if (userInfo === null || userInfo?.name) {
            setIsLoading(false);
            setStepSwitch(401);
            return;
          }
          postQualification(urlParams.get('UUID') ?? '');
        } catch (error) {
          console.error('Error fetching user info:', error);
          setIsLoading(false);
          setStepSwitch(401);
        }
        handleGetIp();
      } else if (token === null) {
        setIsLoading(false);
        setStepSwitch(401);
      }
    };

    fetchData();
  }, [token]);

  return (
    <Container>
      {isLoading &&
        <NewLoading>
          <CircularLoading isLoading={isLoading} />
        </NewLoading>
      }
      {stepSwitch === 0 && <NotFound codeError={'-9'} title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 401 && <NotFound codeError={'-6'} title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 1 && <SelectOptIn changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 2 && <TokenForm changeStep={setStepSwitch} changeLoading={setIsLoading} thisStep={stepSwitch} />}
      {stepSwitch === 3 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} />}
      {stepSwitch === 4 && <ReadonlyForm changeLoading={setIsLoading} changeStep={setStepSwitch} />}
      {stepSwitch === 5 && <PasswordForm changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 7 && <Conclusion title="Atualizamos o e-mail e a senha do seu Acesso VR" />}
      {stepSwitch === 9 && <ReadonlyForm valid changeStep={setStepSwitch} changeLoading={setIsLoading} />}
      {stepSwitch === 11 && <NotFound codeError={'-99'} title="Já existe uma edição em andamento" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepSwitch === 22 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictEmail />}
      {stepSwitch === 33 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictPhone />}
      {stepSwitch === 44 && <FormEdit changeLoading={setIsLoading} changeStep={setStepSwitch} conflictCpfprop />}
      {stepSwitch === 55 && <Conclusion title="Sua conta já está qualificada" />}
    </Container>
  );
}
