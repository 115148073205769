import { useEffect, useState } from 'react';
import { BoxForm } from '../../BoxForm';
import { Container, Title, Text, Image } from '../../../global';
import joinhaImg from '../../../assets/iustração_jóia 1.svg';
import redirectCodes from '../../../public/redirectCode.json';

interface ConclusionProps {
  title: string;
}

export function Conclusion({ title }: ConclusionProps) {
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);

  function handleRedirect(par: string) {
    window.location.href = par;
    localStorage.clear();
  }

  window.addEventListener('beforeunload', function () {
    localStorage.clear();
  });

  useEffect(() => {
    const uuid = urlParams.get('UUID');
    const url = new URL(localStorage.getItem('redirecturl') ?? '');

    const qualificacao = localStorage.getItem('qualificacao');
    const idConta = qualificacao ? JSON.parse(qualificacao).idConta : null;

    if (uuid) {
      if (url.searchParams.toString().length > 0) {
        setUrlToRedirect(`${url}&status=${redirectCodes[0].codigo}&UUID=${uuid}` + (idConta ? `&idConta=${idConta}` : ''));
      } else {
        setUrlToRedirect(`${url}?status=${redirectCodes[0].codigo}&UUID=${uuid}` + (idConta ? `&idConta=${idConta}` : ''));
      }
    } else {
      if (url.searchParams.toString().length > 0) {
        setUrlToRedirect(`${url}&status=${redirectCodes[0].codigo}` + (idConta ? `&idConta=${idConta}` : ''));
      } else {
        setUrlToRedirect(`${url}?status=${redirectCodes[0].codigo}` + (idConta ? `&idConta=${idConta}` : ''));
      }
    }
  }, []);

  return (
    <BoxForm buttonRightClick={() => { handleRedirect(urlToRedirect) }} buttonRight={`Ir para tela de entrada da aplicação de origem`}>
      <Container>
        <Image src={joinhaImg} alt="" />
        <Title>
          {title}
        </Title>
        <Text>
          Vá para tela de entrada da sua aplicação de origem e <strong>informe seus dados para entrar</strong>.
          <br /><br />
          Lembre-se de <strong>favoritar o Acesso VR</strong>, pois ele será o único meio de acessar os serviços que contratou.
        </Text>
      </Container>
    </BoxForm>
  );
}
