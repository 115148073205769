import { createStitches } from "@stitches/react"
import bgImg from '../../assets/bg-aside1.svg'

const { styled, css } = createStitches({
    media: {
      mobile: '(max-width: 740px)',
      tablet: '(max-width: 900px)',
    },
});

export const Container = styled('aside', {
    flex: 1,
    maxWidth: '496px',
    backgroundImage: `url(${bgImg})`,
    height: '100%',
    minHeight: '100vh',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    backgroundSize: 'cover', 
    '@tablet': {
        display: 'none',
    }
})

export const Logo = styled('div', {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img' : {
        width: '100%',
    }
})