import { ReactNode } from "react";
import { Container } from "./styles";
import { Icon } from "@vr/ds-react";

interface CollapseProps {
    readonly isCollapsed: boolean;
    readonly active: () => void;
    readonly question: string;
    readonly children?: ReactNode;
}

export function Collapse({ isCollapsed, active, question, children }: CollapseProps) {

    return (
        <Container onClick={() => active()}>
            <div>
                <h4>{question}</h4>
                <Icon icon={isCollapsed ? "chevron-top" : "chevron-bottom"} size={16} />
            </div>
            {isCollapsed && <>{children}</>}
        </Container>
    )
}