import { BrowserRouter } from 'react-router-dom';

import RouterSwitch from './providers/router-switch';
import { AsideDefault } from './components/AsideDefault';
import { styled } from '@stitches/react';

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const Pages = styled('div', {
  flex: 1,
});

function App() {
  return (
    <BrowserRouter>
      <Container>
        <AsideDefault />
        <Pages>
          <RouterSwitch />
        </Pages>
      </Container>
    </BrowserRouter>
  );
}

export default App;
