import { BoxForm } from '../../BoxForm';
import { Alert } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container } from './styles';
import { InsertContacts } from './insertContacts';
import { InsertCpfAndDate } from './insertCpfandDate';
import { InsertName } from './insertName';
import apiService from '../../../services/api';
import { handleGetUserInfo } from '../../../services/userService';

interface FormEditProps {
  readonly changeStep: (step: number) => void;
  readonly edit?: boolean;
  readonly conflictPhone?: boolean;
  readonly conflictEmail?: boolean;
  readonly conflictCpfprop?: boolean;
  readonly newAccount?: boolean;
  readonly changeLoading: (loading: boolean) => void;
}

export function FormEdit({ changeStep, edit, newAccount, conflictPhone, conflictEmail, conflictCpfprop, changeLoading }: FormEditProps) {
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidCell, setInvalidCell] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = localStorage.getItem('token') ?? '';
  const qualification = JSON.parse(localStorage.getItem('forEdit') ?? localStorage.getItem('qualificacao') ?? '{}');

  function handleGetInfoLocalInfo() {
    const info = JSON.parse(localStorage.getItem('forEdit') ?? localStorage.getItem('qualificacao') ?? '{}');
    return info;
  }

  const qualificationInfo = handleGetInfoLocalInfo();

  const [firstname, ...secondName] = qualificationInfo.conta?.nome?.split(" ") ?? '';

  const [conflictCpf, setConflictCpf] = useState(localStorage.getItem('conflictCpf') === 'true');
  const [name, setName] = useState(firstname !== 'null' && firstname !== '' ? firstname : '');
  const [lastName, setLastName] = useState(secondName !== 'null' && secondName !== '' ? secondName.join(' ') : '');
  const [cpfUser, setCpfUser] = useState(qualificationInfo.conta?.cpf ?? '');
  const [date, setDate] = useState(qualificationInfo.conta?.dataNascimento ?? '');
  const [email, setEmail] = useState(qualificationInfo.conta?.email ?? '');
  const [cell, setCell] = useState(qualificationInfo.conta?.celular ?? '');
  const [etapa, setEtapa] = useState(edit ? 4 : newAccount ? 1 : 3);
  const [errorCpf, setErrorCpf] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [switchForm, setSwitchForm] = useState(1);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    switch (true) {
      case conflictEmail || conflictPhone:
        setSwitchForm(3);
        break;
      case conflictCpfprop:
        setSwitchForm(2);
        break;
      default:
        setSwitchForm(1);
        break;
    }
  }, [conflictEmail, conflictPhone, conflictCpfprop]);

  async function postQualification(cpf: string): Promise<void> {
    changeLoading(true);

    try {
      const res = await apiService.post('/acesso-vr/v1/qualificacoes', {
        aplicacaoOrigem: {
          idAplicacao: urlParams.get('client_id'),
          urlRedirecionamento: urlParams.get('redirect_uri')
        },
        conta: {
          nome: name + ' ' + lastName,
          dataNascimento: date,
          cpf: cpf.replace(/\D/g, '')
        },
        processo: 'nova-conta'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        }
      });
      if (token) {
        setSwitchForm(3);
        handleGetUserInfo(urlParams, token, changeStep, changeLoading, res.data.idQualificacao);
      }
    } catch (err: any) {
      const inputString = err.response?.data?.mensagens?.[0]?.descricao || '';
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);
      if (match) {
        const id = match[1];
        if (token) {
          localStorage.setItem('forEdit', JSON.stringify({
            conta: {
              nome: name,
              email: '',
              celular: '',
              cpf: cpf,
              dataNascimento: date,
              nomeSocial: '',
              optInComunicacao: '',
            }
          }))
          setSwitchForm(3);
          await handleGetUserInfo(urlParams, token, changeStep, changeLoading, id);
        }
      } else {
        window.location.href = urlParams.get('redirect_uri') + '?status=-9';
        changeLoading(false);
      }
    } finally {
      changeLoading(false);
    }
  }

  function isValidAge(birthDateString: string): boolean {
    if (birthDateString === '') {
        setInvalidDate(false);
        return false;
    }

    setDate(birthDateString);
    const birthDate = new Date(birthDateString);
    const today = new Date();

    // Verificar se a data de nascimento é inválida (futura ou muito antiga)
    if (birthDate > today || isNaN(birthDate.getTime())) {
        setInvalidDate(true);
        return false;
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    const isValid = age >= 16 && age <= 90;
    setInvalidDate(!isValid);
    return isValid;
}


  function formatDateToISO(date: string) {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0');
    const day = String(d.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function handleSubmit() {
    const forEdit = {
      conta: {
        nome: name + ' ' + lastName,
        cpf: cpfUser,
        dataNascimento: formatDateToISO(date),
        email: email,
        celular: cell,
      }
    }
    try {
      localStorage.setItem('forEdit', JSON.stringify(forEdit));
      if (edit) {
        changeStep(5);
      } else if (newAccount) {
        changeStep(2);
      } else {
        changeStep(4);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  }

  function handleVerifyCpf(cpf: string) {
    const forEdit = {
      conta: {
        nome: name + ' ' + lastName,
        cpf: cpf,
        dataNascimento: formatDateToISO(date),
        email: email,
        celular: cell,
      }
    }
    localStorage.setItem('forEdit', JSON.stringify(forEdit))
    if (qualification.conta?.cpf === cpf.replace(/\D/g, '')) {
      setSwitchForm(switchForm + 1);
      setEtapa(etapa + 1);
    } else {
      apiService.get(`/gestao-contas-vrid/v1/contas/${cpf.replace(/\D/g, '')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        },
      })
        .then((response) => {
          changeStep(44)
        }
        )
        .catch((err) => {
          setSwitchForm(switchForm + 1);
          setEtapa(etapa + 1);
        });
    }
  }
  function handleVerifyEmail(email: string) {
    const forEdit = {
      conta: {
        nome: name + ' ' + lastName,
        cpf: cpfUser,
        dataNascimento: formatDateToISO(date),
        email: email,
        celular: cell,
      }
    }
    localStorage.setItem('forEdit', JSON.stringify(forEdit))
    if (qualification.conta?.email === email) {
      handleSubmit();
    } else {
      apiService.get(`/gestao-contas-vrid/v1/contas/${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
        },
      })
        .then((response) => {
          changeStep(22)
        }
        )
        .catch((err) => {
          handleSubmit();
        });
    }
  }

  function handleNextForm(step: number) {
    if (step === 2 && newAccount) {
      postQualification(cpfUser);
    } else if (step === 2) {
      handleVerifyCpf(cpfUser);
    } else if (step > 2) {
      handleVerifyEmail(email);
    } else {
      setSwitchForm(switchForm + 1);
      setEtapa(etapa + 1);
    }
  }

  function handleCkeckIsValidScreen(step: number) {
    switch (step) {
      case 1:
        setIsBlocked(!(name !== "" && lastName !== ""));
        break;
      case 2:
        setIsBlocked(!(cpfUser !== '' && errorCpf === false && date !== '' && invalidDate === false));
        break;
      case 3:
        setIsBlocked(!(email !== '' && invalidEmail === false && cell.length === 15 && invalidCell === false));
        break;
      default:
        setIsBlocked(true);
        break;
    }
  }

  useEffect(() => {
    isValidAge(date);
    handleCkeckIsValidScreen(switchForm);
  }, [name, lastName, switchForm, cpfUser, date, email, cell])

  return (
    <BoxForm
      buttonRight="Continuar"
      steps={edit ? 8 : 7}
      completedSteps={etapa}
      buttonRightDesabled={isBlocked}
      buttonRightClick={() => handleNextForm(switchForm)}
    >
      {qualificationInfo.conta && (
        <Container>
          {conflictCpf && <Alert status='error' bodyText='CPF informado já está em uso' />}
          {switchForm === 1 && (
            <InsertName
              invalidName={invalidName}
              lastName={lastName}
              name={name}
              setLastName={(e) => setLastName(e)}
              setName={(e) => setName(e)}
              newAccount={newAccount}
            />
          )}

          {switchForm === 2 && (
            <InsertCpfAndDate
              newAccount={newAccount}
              conflictCpf={conflictCpf}
              conflictCpfprop={conflictCpfprop}
              errorCpf={errorCpf}
              setErrorCpf={setErrorCpf}
              date={date}
              setDate={setDate}
              invalidDate={invalidDate}
              cpfUser={cpfUser}
              isValidAge={(e) => isValidAge(e)}
              setConflictCpf={(e) => setConflictCpf(e)}
              setCpfUser={(e) => setCpfUser(e)}
            />
          )}

          {switchForm === 3 && (
            <InsertContacts
              newAccount={newAccount}
              cell={cell}
              email={email}
              setCell={setCell}
              setEmail={setEmail}
              conflictPhone={conflictPhone}
              conflictEmail={conflictEmail}
              invalidEmail={invalidEmail}
              setInvalidEmail={setInvalidEmail}
              setInvalidCell={setInvalidCell}
            />
          )}
        </Container>
      )}
    </BoxForm>
  );
}
