import { useEffect } from "react";
import { Container, Content } from "./styles";
import { ReactNode } from "react";

interface NewModalProps {
    readonly children: ReactNode,
    readonly onRequestClose: () => void
}

export function NewModal({ children, onRequestClose }: NewModalProps) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onRequestClose();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onRequestClose]);

    return (
        <Container onClick={onRequestClose}>
            <Content onClick={(event) => event.stopPropagation()}>
                {children}
                {/* <Button onClick={onRequestClose}>Fechar</Button> */}
            </Content>
        </Container>
    );
}
