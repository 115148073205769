import { styled } from "@stitches/react";

export const Container = styled('div', {
   
})
export const Title = styled('p', {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
})
export const Subtitle = styled('p', {
    fontSize: '14px',
    textAlign: 'center',
})