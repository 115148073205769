import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container, NewLoading } from '../ReRegistration/styles';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { handleGetUserInfo } from '../../services/userService';
import apiService from '../../services/api';
import { getUserInfo } from '../../services/getUserInfo';

export default function AuthPage() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepForm, setStepForm] = useState(99);
  const [start, setStart] = useState(true);
  const token = useAuthToken();
  const redirectUri = urlParams.get('redirect_uri') ?? '';
  const accessToken = urlParams.get('token') ?? '';

  async function postQualification(cpf: string) {
    setIsLoading(true);
    apiService.post('/acesso-vr/v1/qualificacoes', {
      aplicacaoOrigem: {
        idAplicacao: urlParams.get('client_id'),
        urlRedirecionamento: redirectUri
      },
      conta: {
        cpf: cpf
      },
      processo: 'edicao'
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
      },
    }).then(() => {
      if (token) {
        handleGetUserInfo(urlParams, token, setStepForm, setIsLoading, cpf);
      }
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = `${redirectUri}?status=-6`;
        return;
      }

      const inputString = err.response?.data?.mensagens[0]?.descricao || '';
      const regex = /ID: ([a-f0-9-]{36})/;
      const match = inputString.match(regex);

      if (match) {
        const id = match[1];
        if (token) {
          handleGetUserInfo(urlParams, token, setStepForm, setIsLoading, id);
        }
      } else {
        setIsLoading(false);
        setStepForm(0);
      }
    });
  }

  useEffect(() => {
    localStorage.setItem('redirectUri', redirectUri);
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
    localStorage.setItem('userid', urlParams.get('cpf') ?? '');

    if (token && start) {
      getUserInfo().then(userInfo => {
        if (!userInfo?.name) {
          setIsLoading(false);
          setStepForm(401);
          const symbol = redirectUri.includes('?') ? '&' : '?';
          window.location.href = `${redirectUri}${symbol}status=-6`;
          return;
        }

        if (!userInfo.tier) {
          const editUrl = `/edicao?client_id=${urlParams.get('client_id')}&redirect_uri=${encodeURIComponent(redirectUri)}&token=${accessToken}&idConta=${userInfo.sub}`;
          window.location.href = editUrl;
        }

        setStart(false);
      }).catch(() => {
        setIsLoading(false);
        setStepForm(401);
        const symbol = redirectUri.includes('?') ? '&' : '?';
        window.location.href = `${redirectUri}${symbol}status=-6`;
      });
    }

    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    if (userInfo && userInfo.tier) {
      if (redirectUri) {
        const symbol = redirectUri.includes('?') ? '&' : '?';
        window.location.href = `${redirectUri}${symbol}status=0&idConta=${userInfo.sub}`;
      }
    }
  }, [token]);

  return (
    <Container>
      {isLoading &&
        <NewLoading>
          <CircularLoading isLoading={isLoading} />
        </NewLoading>
      }
      {stepForm === 0 && <NotFound codeError={'-9'} title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirectUri} />}
    </Container>
  );
}
