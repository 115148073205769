import { styled } from '@stitches/react';


export const Container = styled('div', {
  width: '100%',
  maxWidth: '400px',
})

export const RadioCombo = styled('div', {
  maxWidth: '500px',
  display: 'block',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '10px',
});

export const Ask = styled('p', {
  fontSize: '16px',
  fontWeight: '700',
})

export const BoxModal = styled('div', {
  maxWidth: '640px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  alignItems: 'center',
})

export const TitleModal = styled('p', {
  fontSize:'22px',
  fontWeight: 'bold',
  textAlign: 'center',
})  

export const TextModal = styled('p', {
  fontSize:'16px',
  fontWeight: 500,
  textAlign: 'center',
})
export const Separator = styled('div', {
  width: '300px',
  margin: '0 auto',
  '& p':{
    textAlign: 'left',
  }
})