import { styled } from "@stitches/react"


export const Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    rowGap: '8px',
    paddingTop: '3px',
})

export const StyledInput = styled('input', {
    width: '100%',
    border: '1px solid rgb(27, 33, 38)',
    borderRadius: '8px',
    padding: '13px 16px',
    fontFamily: 'Montserrat',
})

export const Label = styled('label', {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '140%',
    letterSpacing: '0.25px',
})
export const Hint = styled('p', {
    color: '#BF0000',
    fontSize: '16px',
    fontWeight: '500',
})