import { useState, useEffect } from "react";
import { Container, Resend, Text } from "./styles";

interface CountdownProps {
    readonly second: number;
    readonly onResend: () => void;
}

export function Countdown({ second, onResend }: CountdownProps) {
    const [seconds, setSeconds] = useState(second);

    useEffect(() => {
        setSeconds(second);
    }, [second]);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [seconds]);

    const handleResend = () => {
        setSeconds(second);
        onResend();
    };

    const formatNumber = (number: number) => number.toString().padStart(2, '0');

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        return `${formatNumber(minutes)}:${formatNumber(secs)}`;
    };

    return (
        <Container>
            {seconds > 0 ? (
                <Text>Aguarde {formatTime(seconds)} para enviar outro código.</Text>
            ) : (
                <Resend onClick={handleResend}>Enviar outro código</Resend>
            )}
        </Container>
    );
}
