import { TextInput } from "@vr/ds-react";
import { SubtitleFormEdit, TitleFormEdit } from "./styles";
import { DatePicker } from "../../DatePicker";

interface InsertCpfAndDateProps {
  newAccount?: boolean;
  cpfUser: string;
  conflictCpf: boolean;
  setConflictCpf: (value: boolean) => void;
  setErrorCpf: (value: boolean) => void;
  date: string;
  setDate: (value: string) => void;
  conflictCpfprop?: boolean;
  errorCpf?: boolean;
  setCpfUser: (value: string) => void;
  isValidAge: (birthDateString: string) => void;
  invalidDate?: boolean;
}

export function InsertCpfAndDate({ invalidDate, isValidAge, setCpfUser, errorCpf, conflictCpfprop, newAccount, cpfUser, conflictCpf, setConflictCpf, setErrorCpf, date, setDate }: InsertCpfAndDateProps) {
  function applyCpfMask(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  function handleValidadeCPf(cpf: string) {
    const rawCpf = cpf.replace(/[^\d]+/g, '');

    if (rawCpf.length !== 11 || /^(\d)\1+$/.test(rawCpf)) {
      setErrorCpf(true);
      return false;
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(rawCpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(rawCpf.substring(9, 10))) {
      console.error('CPF inválido');
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(rawCpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(rawCpf.substring(10, 11))) {
      console.error('CPF inválido');
      return false;
    }

    setErrorCpf(false);
    return true;
  }

  return (
    <>
      <TitleFormEdit>
        {newAccount ? 'Informe seu CPF e data de nascimento' : 'Seu CPF e data de nascimento estão certos?'}
      </TitleFormEdit>
      <SubtitleFormEdit>
        {newAccount ? '' : 'Lembre-se que agora é a hora de atualizar, completar ou corrigir o que precisar.'}
      </SubtitleFormEdit>
      <TextInput
        data-testid="cpf-input"
        label="CPF"
        value={cpfUser}
        onChange={e => {
          const maskedCpf = applyCpfMask(e.target.value);
          handleValidadeCPf(maskedCpf);
          setCpfUser(maskedCpf);
          setConflictCpf(false);
        }}
        hasError={conflictCpfprop ?? errorCpf ?? conflictCpf}
        hintText={errorCpf ?? conflictCpf ? 'CPF inválido' : conflictCpfprop ? 'Existe outro Acesso VR com este CPF.' : ''}
      />
      <DatePicker
        label="Data de nascimento"
        value={date}
        hasError={invalidDate}
        onChange={e => {
          isValidAge(e.target.value);
        }}
        type="date"
      />
    </>
  )
}
