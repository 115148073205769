import { styled } from "@stitches/react";
import { defaultTheme } from "@vr/ds-react";

export const theme = defaultTheme;

/*
radii: {
    borderRadius0: effects.borderRadius0,
    borderRadius4: effects.borderRadius4,
    borderRadius8: effects.borderRadius8,
    borderRadius16: effects.borderRadius16,
    borderRadius24: effects.borderRadius24,
    borderRadius100: effects.borderRadius100
  },
  borderWidths: {
    borderWidth0: effects.borderWidth0,
    borderWidth1: effects.borderWidth1,
    borderWidth2: effects.borderWidth2,
    borderWidth4: effects.borderWidth4,
    borderWidth8: effects.borderWidth8,
  }
*/
export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0%',
  rowGap: '8px',
  paddingTop: '3px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Title = styled('h2', {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
})

export const Text = styled('p', {
  fontSize: '16px',
  fontWeight: 'regular',
  lineHeight: '120%',
  letterSpacing: '0.15%',
  textAlign: 'center',
})

export const Image = styled('img', {
  maxWidth: '214px',
})
