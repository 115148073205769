import { styled } from "@stitches/react";

export const Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '10px',
    maxWidth: '700px',
});

export const Title = styled('button', {
    background: 'transparent',
    border: 'none',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'underline',
    color: '#1B2126',
    cursor: 'pointer',
});

export const Text = styled('p', {
    fontSize: '12px',
    fontWeight: 400,
    '& button': {
        background: 'transparent',
        border: 'none',
        color: '#034D2E',
        cursor: 'pointer',
    }
});

export const BoxCollapse = styled('div', {
    width: '100%',
    backgroundColor: '#F5F5F5',
    padding: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
});