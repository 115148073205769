import { useState } from "react";
import { BoxCollapse, Container, Text, Title } from "./styles";

export function CollapseHelp() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Container>
            <Title onClick={() => setIsOpen(!isOpen)}>Não recebi o código.  O que fazer?</Title>

            {isOpen && (
                <BoxCollapse>
                    <Text>
                        1º Confira na sua caixa de Spam (lixo eletrônico) ou promoções.
                    </Text>
                    <Text>
                        2º Tente <button>enviar outro código.</button>
                    </Text>
                    <Text>
                        3º Se mesmo assim não recebeu o código, entre em contato com a gente pelo telefone: 4004-4938.
                    </Text>
                </BoxCollapse>
            )}
        </Container>
    )
}