import logoImg from '../../assets/logo.png'
import { Container, Logo } from './styles'

export function AsideDefault(){
    return(
        <Container>
            <Logo>
                <img src={logoImg} alt="Logo VR Facilita a vida" title="VR Facilita a vida" />
            </Logo>
        </Container>
    )
}