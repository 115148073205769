import { TextInput } from "@vr/ds-react";
import { SubtitleFormEdit, TitleFormEdit } from "./styles";
import { useState } from "react";

interface InsertContactsProps {
    readonly newAccount?: boolean;
    readonly cell?: string;
    readonly email?: string;
    readonly conflictPhone?: boolean;
    readonly conflictEmail?: boolean;
    readonly invalidEmail?: boolean;
    readonly setInvalidEmail: (value: boolean) => void;
    readonly setCell: (value: string) => void;
    readonly setEmail: (value: string) => void;
    readonly setInvalidCell: (value: boolean) => void;
}

export function InsertContacts({ newAccount, cell, email, conflictPhone, conflictEmail, invalidEmail, setInvalidEmail, setCell, setEmail, setInvalidCell }: InsertContactsProps) {
    const [invalidPhone, setInvalidPhone] = useState(false);
    function handleVerifyEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
        }
    }

    function handleMaskPhone(value: string) {
        value = value.replace(/\D/g, '');
        value = value.substring(0, 11);
        if (value.length === 11) {
            const ddd = value.substring(0, 2);
            if (!/^[1-9]{2}$/.test(ddd)) {
                setCell('');
                return;
            }
    
            value = value.replace(/^([1-9]{2})(\d)/g, '($1) $2');
            value = value.replace(/(\d{5})(\d{4})/, '$1-$2');
            
            setCell(value);
            const firstDigit = value.charAt(5);
            if (!/^[89]$/.test(firstDigit)) {
                setInvalidPhone(true);
                setInvalidCell(true);
                return;
            }
    
        } else {
            value = value.replace(/^([1-9]{2})(\d)/g, '($1) $2');
            setCell(value);
        }
    }

    return (
        <>
            <TitleFormEdit>
                {newAccount ? 'Insira suas informações de contato' : 'Atualize também suas informações de contato'}
            </TitleFormEdit>
            <SubtitleFormEdit>
                Informe um e-mail que só você usa, pois o Acesso VR é individual e sempre fazemos verificações para garantir a segurança dos seus dados.
            </SubtitleFormEdit>
            <TextInput
                data-testid="email-input"
                label="E-mail"
                hasError={invalidEmail || conflictEmail}
                hintText={invalidEmail ? 'O email informado é inválido' : conflictEmail ? 'Existe outro Acesso VR com este e-mail. Informe um que só você usa.' : 'Informe um e-mail que só você usa.'}
                value={email}
                onChange={e => {
                    setEmail(e.target.value);
                    handleVerifyEmail(e.target.value);
                }}
            />
            <TextInput
                label="Celular"
                data-testid="phone-input"
                value={cell}
                hasError={conflictPhone || invalidPhone}
                hintText={conflictPhone ? 'Existe outro Acesso VR com este celular. Informe um que você usa.' : invalidPhone ? 'O número de celular informado é inválido.' : ''}
                onChange={e => {
                    setInvalidPhone(false);
                    setInvalidCell(false);
                    handleMaskPhone(e.target.value)
                }}
            />
        </>
    )
}