import apiService from '../../../services/api';
import { BoxForm } from '../../BoxForm';
import { InputToken } from '../../InputToken';
import { TextInfo } from '../../TextInfo';
import { useEffect, useState } from 'react';
import { CollapseHelp } from './CollapseHelp';
import { Countdown } from '../../Countdown/index';
import { handleVerifyState } from '../../../services/verifyState';
import { Alert } from '@vr/ds-react';

interface TokenProps {
  readonly changeStep: (step: number) => void;
  readonly changeLoading: (loading: boolean) => void;
  readonly thisStep: number;
  readonly edit?: boolean;
  readonly newAccount?: boolean;
}

export function TokenForm({ changeStep, changeLoading, thisStep, edit, newAccount }: TokenProps) {
  const [invalid, setInvalid] = useState(false);
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const qualificationInfo = JSON.parse(localStorage.getItem('qualificacao') ?? '{}');
  const [code, setCode] = useState<number>(0);
  const estado = qualificationInfo.estado;
  const [errorResend, setErrorResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Algo de errado aconteceu, contate o admnistrador do sistema.');

  function getEvento(estado: string, resend?: boolean): string {
    if (resend) {
      switch (estado) {
        case 'mfa-enviado-email-opt-in':
          return 'reenvio-mfa-email-opt-in';
        case 'mfa-enviado-telefone-opt-in':
          return 'reenvio-mfa-telefone-opt-in';
        default:
          return 'reenvio-mfa-normal';
      }
    } else {
      switch (estado) {
        case 'mfa-enviado-email-opt-in':
          return 'mfa-validado-email-opt-in';
        case 'mfa-enviado-telefone-opt-in':
          return 'mfa-validado-telefone-opt-in';
        default:
          return 'mfa-validado-normal';
      }
    }
  }

  const evento = getEvento(estado);

  function formatDateToISO(date: string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function fillZero(token: string) {
    return token.padStart(6, '0');
  }

  function handleVerifyCode() {
    changeLoading(true);
    apiService
      .put(
        `/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`,
        {
          evento: evento,
          aplicacaoOrigem: {
            idAplicacao: urlParams.get('client_id'),
            urlRedirecionamento: urlParams.get('redirect_uri'),
          },
          conta: {
            codigoMfa: fillZero(code.toString()),
            cpf: qualificationInfo ? qualificationInfo.conta?.cpf : '',
            editar: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
          },
        },
      )
      .then((resp) => {
        setInvalid(false);
        setCode(0);
        handleVerifyState(changeStep, changeLoading, resp.data);
      })
      .catch(() => {
        changeLoading(false);
        setInvalid(true);
      });
  }

  function handleResendCode() {
    const evt = getEvento(estado, true);
    apiService
      .put(
        `/acesso-vr/v1/qualificacoes/${qualificationInfo.idQualificacao}`,
        {
          evento: evt,
          aplicacaoOrigem: {
            idAplicacao: urlParams.get('client_id'),
            urlRedirecionamento: urlParams.get('redirect_uri'),
          },
          conta: {
            cpf: qualificationInfo ? qualificationInfo.conta?.cpf : '',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
          },
        },
      )
      .then(() => {
        setErrorResend(false);
        setCode(0);
        console.log('reenviado');
      }).catch((err) => {
        changeLoading(false);
        setErrorResend(true);
      });
  }

  function handleGoBack() {
    changeStep(estado === 'mfa-enviado' ? thisStep - 1 : thisStep + 1);
  }

  return (
    <>
      {qualificationInfo.conta && (
        <BoxForm
          steps={edit ? 8 : 7}
          completedSteps={edit ? 3 : newAccount ? 6 : 2}
          heading={
            estado === 'mfa-enviado-telefone-opt-in'
              ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.celular}`
              : estado === 'mfa-enviado-email-opt-in'
                ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.email}`
                : estado === 'mfa-enviado' && qualificationInfo.conta.optInComunicacao === 'EMAIL'
                  ? `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.email}`
                  : `Insira o código de 6 números que enviamos para ${qualificationInfo.conta.celular}`
          }
          buttonRight="Verificar"
          buttonLeft="Voltar"
          buttonRightClick={() => handleVerifyCode()}
          buttonLeftClick={() => handleGoBack()}
        >
          {errorResend && <Alert status="error" bodyText={errorMessage} css={{ padding: '0px 10px' }} />}
          {['mfa-enviado-telefone-opt-in', 'mfa-enviado-email-opt-in'].includes(estado) ? (
            <TextInfo>
              Como você atualizou esta informação de contato, precisamos garantir que está correta e que você ainda tem acesso a ela.
              <br /> <br />
            </TextInfo>
          ) : (
            <TextInfo>
              {qualificationInfo.conta.optInComunicacao === 'EMAIL' && (
                <>
                  Busque pelo e-mail enviado por <strong>naoresponda@vr.com.br.</strong>
                </>
              )}
              <br /> <br />
            </TextInfo>
          )}
          {invalid && <Alert status="error" bodyText='O código que você inseriu está inválido. Verifique e tente novamente.' css={{ padding: '0px 10px' }} />}
          <InputToken
            onChange={(e) => {
              setCode(parseInt(e));
              setInvalid(false);
            }}
            label="Informe o código de verificação"
            error={invalid}
            code={code}
          />
          <Countdown onResend={() => handleResendCode()} second={60} />
          <CollapseHelp />
        </BoxForm>
      )}
    </>
  );
}
