import { BoxForm } from '../../BoxForm';
import joinhaImg from '../../../assets/Feedback.svg';

import { Container, Title, Text, Image } from '../../../global';
import { useEffect, useState } from 'react';
import redirectCodes from '../../../public/redirectCode.json';

interface NotFoundProps {
  readonly title?: string;
  readonly text?: string;
  readonly textButton: string;
  readonly buttonRedirect: string;
  readonly codeError: string;
}

export function NotFound({ title, text, textButton, buttonRedirect, codeError }: NotFoundProps) {
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const [showButton, setShowButton] = useState(true);


  function handleRedirect() {
    localStorage.removeItem('redirecturl');
    localStorage.removeItem('pre-account');
    localStorage.removeItem('clientid');
    localStorage.removeItem('userid');
    localStorage.removeItem('qualificacao');
    localStorage.removeItem('token');
    window.location.href = urlToRedirect
  }

  useEffect(() => {
    const uuid = urlParams.get('UUID');
    if (buttonRedirect !== '' && uuid) {
      const url = new URL(buttonRedirect);
      const selectErrorCode = redirectCodes.find(code => code.codigo === codeError);
      if (url.searchParams.toString().length > 0) {
        return setUrlToRedirect(`${url}&status=${selectErrorCode?.codigo}&UUID=${uuid}`);
      } else {
        return setUrlToRedirect(`${url}?status=${selectErrorCode?.codigo}&UUID=${uuid}`);
      }
    } else if (buttonRedirect !== '') {
      const url = new URL(buttonRedirect);
      const selectErrorCode = redirectCodes.find(code => code.codigo === codeError);
      if (url.searchParams.toString().length > 0) {
        return setUrlToRedirect(`${url}&status=${selectErrorCode?.codigo}`);
      } else {
        return setUrlToRedirect(`${url}?status=${selectErrorCode?.codigo}`);
      }
    }
    else {
      return setShowButton(false);
    }

  })

  return (
    <BoxForm heading="" buttonRightClick={() => handleRedirect()} buttonRight={showButton ? textButton : ''}>
      <Container>
        <Image src={joinhaImg} alt="" />
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
      </Container>
    </BoxForm>
  );
}
