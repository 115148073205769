import { BoxForm } from '../../BoxForm';
import { TextInput } from '@vr/ds-react';
import { Container, InputLine } from './styles';
import { TextInfo } from '../../TextInfo';

interface ReadyonlyProps {
  readonly changeStep: (step: number) => void;
  readonly prev?: boolean;
  readonly valid?: boolean;
  readonly edit?: boolean;
  readonly newAccount?: boolean;
  readonly changeLoading: (loading: boolean) => void;
}

export function ReadonlyForm({ changeStep, prev, edit, newAccount, changeLoading, valid }: ReadyonlyProps) {
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const qualificationInfo = JSON.parse(
      localStorage.getItem(prev || valid ? 'qualificacao' : 'forEdit') ?? '{}',
    );

  const [firstname, ...secondName] = qualificationInfo.conta?.nome.split(" ") ?? ['',''];

  const formatDate = (timestamp: string | number | Date) => {
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <BoxForm
      heading={prev ? "Revise seus dados" : "Está é a última chance de revisar seus dados"}
      buttonRight="Está tudo certo, continuar."
      buttonLeft="Alterar meus dados"
      steps={edit ? 8 : 7}
      completedSteps={prev ? 1 : edit ? 7 : newAccount ? 4 : 6}
      buttonLeftClick={() => {
        if (prev) {
          changeStep(2)
        } else if(newAccount){
          localStorage.setItem('goBack', 'true')
          changeStep(1)
        }else {
          if (edit && !prev) {
            changeStep(4)
          } else {
            changeStep(3); localStorage.setItem('isEdit', 'true')

          }
        }
      }}
      buttonRightClick={() => {
        if (prev) {
          window.location.replace(urlParams.get('redirect_uri') ?? '')
        } else if(newAccount){
          changeStep(3)
        }else {
          if (edit) {
            changeStep(6)
          } else {
            changeLoading(true)
            changeStep(5)
          }
        }
      }}
    >
      {qualificationInfo && (
        <Container>
          <TextInfo>
            Corrija tudo o que estiver errado, desatualizado ou incompleto para não ter problemas quando for acessar as plataformas e usar os seus serviços.
          </TextInfo>
          <InputLine>
            <TextInput data-testid="nome" label="Nome" disabled value={firstname ?? ''} />
            <TextInput label="Sobrenome" disabled value={secondName.join(' ') ?? ''} />
          </InputLine>
          <InputLine>
            <TextInput label="CPF" disabled value={qualificationInfo.conta.cpf ?? ''} />
            <TextInput label="Data de nascimento" disabled value={formatDate(qualificationInfo.conta.dataNascimento ?? '')} />
          </InputLine>
          <InputLine>
            <TextInput hintText='Informe um e-mail que só você usa.' label="E-mail" disabled value={qualificationInfo.conta.email ?? ''} />
            <TextInput label="Celular" disabled value={qualificationInfo.conta.celular ?? ''} />
          </InputLine>
        </Container>
      )}
    </BoxForm>
  );
}
