import { createStitches } from '@stitches/react';

const { styled } = createStitches({
  media: {
    mobile: '(max-width: 740px)',
    tablet: '(max-width: 900px)',
  },
});

export const Container = styled('div', {
  height: '100%',
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  justifyContent: 'space-between',
  padding: '20px 50px',
  '@mobile': {
    padding: '10px 10px 20px 10px',
  },
});
export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  gap: '20px',
  width: '100%',
})

export const Head = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const LogoAndHelp = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@mobile': {
    display: 'flex',
  },
  width: '100%',
})

export const Logo = styled('img', {
  opacity: 0,
  '@tablet': {
    opacity: 1,
  },
});

export const Foot = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    flex: 1,
    maxWidth: '350px',
  },
  '@tablet': {
    '& button': {
      flex: 1,
      width: '100%',
      maxWidth: '100%',
    },
  },
  '@mobile': {
    flexDirection: 'column',
  },
});

export const Title = styled('h1', {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
});
