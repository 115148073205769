import { useEffect, useState } from 'react';
import Environment from '../enviroment';
import apiService from "../services/api";
import { useEncription } from './useEncription';

export function useAuthToken() {
  const [pkceData, loading, error, generatePKCEData] = useEncription();
  const { xData, xSignature } = pkceData || {};
  const [token, setToken] = useState<string | null | undefined>(undefined);

  const fetchSignature = async () => {
    if (!xData || !xSignature) {
      console.warn("xData or xSignature is null. Cannot fetch signature.");
      return null;
    }
    try {
      const response = await fetch(`${Environment.apiAcessoVrAuthUtils}/jwt-utils/v1/jwt-assinado?clientId=${Environment.jwtClientId}`, {
        method: 'GET',
        headers: {
          "x-data": xData,
          "x-signature": xSignature,
        },
      });
      const data = await response.json();
      return data.signedToken;
    } catch (error) {
      console.error('Error fetching token:', error);
      setToken(null);
    }
  };

  const fetchToken = async () => {
    try {
      const signedToken = await fetchSignature();
      if (!signedToken) return setToken(null);
      const result = await apiService.post('/autenticacao-vrid-sso/v1/oauth/token', {
        grantType: "client_credentials",
        clientId: `${Environment.jwtClientId}`,
        clientAssertionType: "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
        clientAssertion: `${signedToken}`,
      },
        {
          headers: {
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID,
            "Content-Type": "application/json",
          },
        });
      const newToken = result.data?.accessToken;
      localStorage.setItem('token', newToken);
      setToken(newToken);
    } catch (error) {
      console.error('Error fetching access token:', error);
      setToken(null);
    }
  }

  useEffect(() => {
    const initialize = async () => {
      if (!pkceData) {
        await generatePKCEData();
      } else {
        await fetchToken();
      }
    };

    initialize();

    const intervalId = setInterval(() => {
      generatePKCEData()
    }, 290000);

    return () => clearInterval(intervalId);
  }, [pkceData, generatePKCEData]);

  return token;
}
