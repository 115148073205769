import apiService from "./api";


export async function verifyClientAndRedirectUri(token: string) {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id');
    const redirectUri = urlParams.get('redirect_uri');
    const uuid = urlParams.get('UUID');

    try {
        const res = await apiService.get(`/acesso-vr/v1/aplicacoes/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
            },
        });
        if (res.data.urlsRedirecionamento) {
            const isMatch = res.data.urlsRedirecionamento.find((url: string | null) => url === redirectUri)
            if (!isMatch && uuid !== null) {
                window.location.replace(`${redirectUri}/&status=-9&UUID=${uuid}`);
            } else if(!isMatch){
                window.location.replace(`${redirectUri}/&status=-9`);
            }
        } else {
            window.location.replace(`${redirectUri}/&status=-9`);
        }
    } catch (error) {
        window.location.replace(`${redirectUri}/&status=-9`);
    }
}