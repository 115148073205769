import { useLocation } from 'react-router-dom';
import { CircularLoading } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Container, NewLoading } from '../ReRegistration/styles';
import { TokenForm } from '../../components/FormsScreen/TokenForm';
import { ReadonlyForm } from '../../components/FormsScreen/ReadonlyForm';
import { FormEdit } from '../../components/FormsScreen/FormEdit';
import { Conclusion } from '../../components/FormsScreen/Conclusion';
import { SelectOptIn } from '../../components/FormsScreen/SelectOptIn';
import { NotFound } from '../../components/FormsScreen/NotFound';
import { useAuthToken } from '../../hooks/useAuthToken';
import { PasswordForm } from '../../components/FormsScreen/PasswordForm';
import { getUserInfo } from '../../services/getUserInfo';
import { handleGetIp } from '../../services/getIp';
import { verifyClientAndRedirectUri } from '../../services/verifyClientId';

export default function NewAccountPage() {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const [stepForm, setStepForm] = useState(99);
  const token = useAuthToken();
  const redirecturl = urlParams.get('redirect_uri') ?? '';
  const [start, setStart] = useState(true);

  useEffect(() => {
    
    localStorage.setItem('redirecturl', urlParams.get('redirect_uri') ?? '');
    localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
    const fetchData = async () => {
      if (token && start) {
        verifyClientAndRedirectUri(token);
        localStorage.clear();
        localStorage.setItem('redirecturl', redirecturl);
        localStorage.setItem('clientid', urlParams.get('client_id') ?? '');
        localStorage.setItem('token', token);
        localStorage.setItem('forEdit', JSON.stringify({
          conta: {
            nome: '',
            email: '',
            celular: '',
            cpf: '',
            dataNascimento: '',
            nomeSocial: '',
            optInComunicacao: '',
          }
        }))
        setStart(false);
        try {
          const userInfo = await getUserInfo();
          if (userInfo === null || userInfo?.name) {
            setIsLoading(false);
            setStepForm(401);
            return;
          }
          setIsLoading(false);
          setStepForm(1);
        } catch (error) {
          console.error('Error fetching user info:', error);
          setIsLoading(false);
          setStepForm(401);
        }
        handleGetIp();
      } else if (token === null) {
        setIsLoading(false);
        setStepForm(401);
      }
    };
    fetchData();
  }, [token]);

  return (
    <Container>
      {isLoading &&
        <NewLoading>
          <CircularLoading isLoading={isLoading} />
        </NewLoading>
      }
      {stepForm === 0 && <NotFound codeError={'-9'} title="Não foi possível localizar seu cadastro" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepForm === 401 && <NotFound codeError={'-6'} title="Oops! Parece que você não tem permissão para acessar esta página" text='Entre em contato com a central de ajuda ou retorne a plataforma de origem.' textButton='Voltar' buttonRedirect={redirecturl} />}
      {stepForm === 1 && <FormEdit newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 2 && <ReadonlyForm changeLoading={setIsLoading} newAccount changeStep={setStepForm} />}
      {stepForm === 3 && <SelectOptIn newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 4 && <TokenForm newAccount changeStep={setStepForm} changeLoading={setIsLoading} thisStep={stepForm} />}
      {stepForm === 5 && <PasswordForm newAccount changeStep={setStepForm} changeLoading={setIsLoading} />}
      {stepForm === 7 && <Conclusion title="Atualizamos o e-mail e a senha do seu Acesso VR" />}
    </Container>
  );
}
