import { Button, Modal } from '@vr/ds-react';
import { useEffect, useState } from 'react';
import { Line, ModalContent } from './styles';
import apiService from '../../../services/api';
import axios from 'axios';
import { NewModal } from '../../NewModal';
import { formatDateToISO } from '../PasswordForm';

interface TermsProps {
  readonly changeAccepted: (check: boolean, ip: string, version: string) => void;
  readonly changeLoading: (loading: boolean) => void;
}

export function Terms({ changeAccepted, changeLoading }: TermsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [version, setVersion] = useState('');
  const [content, setContent] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [ip, setIP] = useState('');
  const qualificationInfo = JSON.parse(localStorage.getItem('qualificacao') ?? '{}');
  const versionTermsQualification = qualificationInfo.conta.termosDeUso;

  async function handleGetHtmlFromLink(url: string) {
    try {
      const response = await axios.get(url);
      setContent(response.data);
    } catch (error) {
      console.error('Error fetching HTML content:', error);
    }
  }

  async function handleGetTerms() {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token não encontrado no localStorage');
      return;
    }

    try {
      const response = await apiService.get(
        `/gestao-contas-vrid/v1/termo-vigente`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            client_id: process.env.REACT_APP_SENSEDIA_CLIENT_ID
          },
        },
      );
      const termsResponse = response.data;
      setVersion(termsResponse.versao);
      await handleGetHtmlFromLink(termsResponse.url);

      changeLoading(false);

    } catch (error) {
      console.error('Erro ao buscar termos:', error);
      changeLoading(false);
    }
  }

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIP(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };
    handleGetTerms();
    fetchIP();
  }, []);

  useEffect(() => {
    if(versionTermsQualification == version){
      setIsChecked(true)
      changeAccepted(true, ip, version)
      localStorage.setItem('version', JSON.stringify({version: version, ip: ip}));
    } else {
      setIsChecked(false)
    }
  }, [version, ip]);

  function handleSaveIpAndVersionTerm(ver: string, ip: string){    
    localStorage.setItem('version', JSON.stringify({version: ver, ip: ip}));
  }

  return (
    <>
      {isOpen &&
        <NewModal onRequestClose={() => setIsOpen(false)}>
          <ModalContent dangerouslySetInnerHTML={{ __html: content }} />
        </NewModal>
      }
      <Line>
        <br />
        <br />
        <input
        data-testid="checkbox-input"
          type="checkbox"
          checked={isChecked}
          onChange={e => {handleSaveIpAndVersionTerm(version, ip);changeAccepted(e.target.checked, ip, version); setIsChecked(e.target.checked)}}
        />{' '}
        Eu li e aceito os
        <Button variant="ghost" size="small" onClick={() => setIsOpen(true)}>
          termos de uso do Acesso VR.
        </Button>
      </Line>
    </>
  );
}