import { Container, Hint, Label, StyledInput } from "./styles";

interface DatePickerProps {
    readonly type?: string;
    readonly hasError?: boolean;
    readonly label?: string;
    readonly value?: string;
    readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readonly styles?: {
        inputStyle?: any;
    };
}

export function DatePicker({ type, label, value, onChange, styles, hasError }: DatePickerProps) {
    return (
        <Container>
            <Label>{label}</Label>
            <StyledInput
                data-testid="date"
                type={type}
                value={value}
                onChange={onChange}
                css={styles?.inputStyle}
            />
            {hasError && <Hint>Data de nascimento inválida</Hint>}
        </Container>
    );
}
