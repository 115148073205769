import { styled } from '@stitches/react';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  minHeight: '100vh',
});

export const NewLoading = styled('div', {
  zIndex: 9999
})

export const MessageBox = styled('div', {
  width: '600px',
  height: '500px',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
  borderRadius: '0px 40px 0px 40px',
});

export const CopyBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '10px',
  left: '0px',
  width: '100%',
});

export const Text = styled('p', {
  fontSize: '14px',
  fontWeight: '500',
});
