import { globalStyles, ThemeProvider } from '@vr/ds-react';

interface AppProviderProps {
  readonly children: React.ReactNode;
}

globalStyles();
export default function AppProvider({ children }: AppProviderProps) {
  return (
    <>
      <ThemeProvider emissor='VRPAT' />
      {children}
    </>
  );
}
